<template>
    <v-card class="white border-boxGlobal">
        <v-card-title>
            Promedio Ponderado
        </v-card-title>
      
        <VersatileFilter :columnsSearch="columsConfig" :filterEndpoint="$API.inventoryReports.getFilterWeightedAverage" :search="search" @emptyFilter="getEarningDetail" @filtered="filterHandler"
        :key="Reloadfilter" :columnfilterEndpoint="$API.inventoryReports.getColReportData" class="mx-3"  :requiredParams="true"
      >
       <!--  <GeneralFilter  class="background border-box" :filterEndpoint="$API.inventoryReports.getFilterWeightedAverage" :search="search" @emptyFilter="getEarningDetail" @filtered="filterHandler">
 -->
        <DxDataGrid width="100%"   @exporting="onExporting" :scrolling="{useNative:true}"  :data-source="reportsData" :show-borders="true">

          <DxExport :enabled="true" />
                
            <DxColumn :width="columnWidth" data-field="NotFormattedDate" caption="Fecha" />

                <DxColumn :width="columnWidth" caption="Compras" >
                    <DxColumn :width="subcolumnWidth" data-field="PurcharseItemQuantity" caption="Cantidad" />
                    <DxColumn :width="subcolumnWidth"  data-field="PurcharseItemUnitCost" caption="Costo Unidad" />
                    <DxColumn :width="subcolumnWidth"  data-field="PurcharseItemTotalCost" caption="Costo Total" />
                </DxColumn>
                <DxColumn caption="Ventas" >
                    <DxColumn :width="subcolumnWidth"  data-field="SalesItemQuantity" caption="Cantidad" />
                    <DxColumn :width="subcolumnWidth"  data-field="SalesItemUnitCost" caption="Costo Unidad" />
                    <DxColumn :width="subcolumnWidth"  data-field="SalesItemTotalCost" caption="Costo Total" />
                </DxColumn>
                <DxColumn :width="columnWidth" caption="Saldos" >
                    <DxColumn :width="subcolumnWidth"  data-field="balances_Item_Quantity" caption="Cantidad" />
                    <DxColumn :width="subcolumnWidth"  data-field="balances_ItemUnitCost" caption="Costo Unidad" />
                    <DxColumn :width="subcolumnWidth"  data-field="balances_ItemTotalCost" caption="Costo Total" />
                </DxColumn>

                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />

                <DxPaging :page-size="10" />
            </DxDataGrid>
            </VersatileFilter>
       <!--  </GeneralFilter> -->
        <v-col cols="12" md="12" class="d-flex justify-center align-center">
            <h1>
               Totales
            </h1>
        </v-col>
        <v-row>
          
            <v-col cols="12" md="3">
                <v-card-title>
                    Unidades Vendidas: {{ summary.totalUnitSales }}
                </v-card-title>
            </v-col>
            <v-col cols="12" md="3">
                <v-card-title>
                    Total Ventas: {{ Currency + ' '+ applyNumberWithCommas( summary.totalCostSales ) }}
                </v-card-title>
            </v-col>
            <v-col cols="12" md="3">
                <v-card-title>
                    Unidades Compradas: {{ summary.totalUnitPurchases }}
                </v-card-title>
            </v-col>
            <v-col cols="12" md="3">
                <v-card-title>
                    Total Compras: {{ Currency + ' '+ applyNumberWithCommas( summary.totalCostPurchases ) }}
                </v-card-title>
            </v-col>
           
            
        </v-row>
       


    </v-card>

</template>

<script>
//import GeneralFilter from '@/components/GeneralFilter'
import VersatileFilter from '@/components/VersatileFilter'
import { DxDataGrid, DxColumn, DxPager, DxPaging, DxExport } from 'devextreme-vue/data-grid';
import { printToExcel } from "@/helpers/printToexcel";
//import DatePicker from '@/components/DatePicker';
import { numberWithCommas } from '@/helpers/money';

    export default {
        name: "WeightedAverage",
        components: {
          //  DatePicker,
            DxDataGrid,
            DxColumn,
            VersatileFilter,
           // GeneralFilter,
            DxExport,
            DxPager,
            DxPaging,
        },
        props: ['win','datafilter'],

        data() {
            return {
                panel: 0,
                reportsData : [],
                start: null,
                Reloadfilter: 0,
                end: null,
                summary: {
                    totalUnitSales: 0,
                    totalCostSales: 0,
                    totalCostPurchases: 0,
                    totalUnitPurchases: 0,

                },
                search: {
                    methods: 'average'
                },
                Currency: null,
                columsConfig: [
                    {
                        name: 'Warehouse',
                        title: 'Almacenes',
                        value: '',
                        type: 'select',
                        options: [],
                    },
                    {
                        name: 'providers_id',
                        title: 'Proveedores',
                        value: '',
                        type: 'select',
                        options: [],
                    },
                    {
                        name: "Id_family",
                        title: "Familia",
                        value: '',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "Id_category",
                        title: "Categoría",
                        parent: 'Id_family',
                        value: '',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "Id_subcategory",
                        title: "Subcategoría",
                        parent: 'Id_category',
                        value: '',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "ID_ItemBatch",
                        title: "Lote",
                        value: '',
                        type: "select",
                        options: [],
                    }
                ],

            }
        },
        computed:{
            columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '200px' : 'auto !important';
  }, 
  subcolumnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '130px' : 'auto !important';
  }, 
        },
        methods: {
            filterHandler(data) {
                this.sumvalues(data);
                this.reportsData = data.map(item => {
                      
                        item.PurcharseItemUnitCost = this.Currency +  numberWithCommas(item.PurcharseItemUnitCost);
                        item.PurcharseItemTotalCost = this.Currency +  numberWithCommas(item.PurcharseItemTotalCost);
                       
                        item.SalesItemUnitCost = this.Currency +  numberWithCommas(item.SalesItemUnitCost);
                        item.SalesItemTotalCost = this.Currency +  numberWithCommas(item.SalesItemTotalCost);
                       
                        item.balances_ItemUnitCost = this.Currency +  numberWithCommas(item.balances_ItemUnitCost);
                        item.balances_ItemTotalCost = this.Currency +  numberWithCommas(item.balances_ItemTotalCost);
                        return item
                    });
            
            },
            applyNumberWithCommas( value ){
                return numberWithCommas( value );
            },
            assignParams(){
                if(this.datafilter){
                    this.columsConfig[0].options = this.datafilter.Warehouses;
                    this.columsConfig[1].options = this.datafilter.providers;
                    this.columsConfig[2].options = this.datafilter.Families;
                    this.columsConfig[3].options = this.datafilter.Categories;
                    this.columsConfig[4].options = this.datafilter.SubCategories;
                    this.columsConfig[5].options = this.datafilter.batches;
                    this.Reloadfilter += 1;
                }
            },
            sumvalues(data){
                this.summary = {
                            totalUnitSales: 0,
                            totalCostSales: 0,
                            totalCostPurchases: 0,
                            totalUnitPurchases: 0,
                        }
                if(data.length > 0){
                data.forEach(element => {
                    //only two decimals
                    this.summary.totalUnitSales = this.summary.totalUnitSales + element.SalesItemQuantity
                    this.summary.totalCostSales = this.summary.totalCostSales + element.SalesItemTotalCost
                    this.summary.totalCostPurchases = this.summary.totalCostPurchases + element.PurcharseItemTotalCost
                    this.summary.totalUnitPurchases = this.summary.totalUnitPurchases + element.PurcharseItemQuantity
                })      
                    
             }
            },
            getEarningDetail() {
                this.$API.inventoryReports.getWeightedAverage({
                    start: this.start,
                    end: this.end                
                }).then(response => {
                    this.sumvalues(response)

                    this.reportsData = response.map(item => {
                       
                        item.PurcharseItemUnitCost = this.Currency +  numberWithCommas(item.PurcharseItemUnitCost);
                        item.PurcharseItemTotalCost = this.Currency +  numberWithCommas(item.PurcharseItemTotalCost);
                      
                        item.SalesItemUnitCost = this.Currency +  numberWithCommas(item.SalesItemUnitCost);
                        item.SalesItemTotalCost = this.Currency +  numberWithCommas(item.SalesItemTotalCost);
                       
                        item.balances_ItemUnitCost = this.Currency +  numberWithCommas(item.balances_ItemUnitCost);
                        item.balances_ItemTotalCost = this.Currency +  numberWithCommas(item.balances_ItemTotalCost);
                        return item
                    })

                }).
                catch(error => {
                    console.log(error);
                })
            },
            onExporting(e){
                printToExcel(e,'Costo Ponderado')
            },
            changueStr(date) {
                this.start = date;
                this.search.start = date;
            },
            changueEnd(date) {
                this.end = date;
                this.search.end = date;
            }
        },
        mounted() {
            this.Currency = JSON.parse(localStorage.getItem('branch')).Currency;
            this.assignParams();
            this.getEarningDetail();
        }
    }
</script>

<style scoped>
    
</style>
